$flexslider__control-nav__active--color: white ;
$flexslider__control-nav__normal--color: grey ;
$flexslider__control-nav__hover--color: white ;
.flexslider__nav {
  display: flex;

  .flex-direction-nav {
    z-index: 80;
  }

  .flexslider__nav__container {
    position: absolute;
    bottom: 0px;
    z-index: 91;
  }

  .flex-control-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 30px;
    width: 100vw;
    align-self: flex-end;
    z-index: 90;

    li {
      width: 25px;
      text-align: center;
      margin: 0 6px;
      display: inline-block;
      zoom: 1;
      *display: inline;

      .flex-active {
        color: $flexslider__control-nav__active--color;
        i {
          font-weight: 900;
        }
      }

      a {
        width: 25px;
        text-align: center;
        color: $flexslider__control-nav__normal--color;
      }

      a:hover {
        color: $flexslider__control-nav__hover--color;
      }
    }
  }
}

.flexslider {
  .slides {
    .slidercontext {
      position: absolute;
      /* z-index: 9999;
      bottom: 50px;
      right: 0px;
      width: 570px;
      text-align: left;
      height: unset;
      padding: 10px 20px 20px;
      background: rgba(255,255,255,0.9);
      opacity: 0; */
      top: 39%;
      font-size: 96px;
      color: rgba(32,32,32,0.75);
      font-size: 6vw;
      mix-blend-mode: color-burn;




      transform: translateX(100px);
      transition: opacity 0.4s ease-in-out 0s, transform 1s ease-in-out 0s;
      @include media-breakpoint-down(xs) {
        display: none !important;
      }

      &, h2, h3, h4, span {
        transform: translateX(100px);

        text-transform:uppercase;
      }


      h2 {
        text-align: left;
        transition: opacity 0.4s ease-in-out 0.7s, transform 1s ease-in-out 0.5s;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      h3 {
        text-align: left;
        padding: 0;
        transition: opacity 0.4s ease-in-out 0.9s, transform 1s ease-in-out 0.7s;
        text-shadow: 0.5px 0.5px 0 #fff;
      }
      h4, span {
        text-align: left;
        color: $body--font-color;
        font-family: $body--font-family;
        padding: 0;
        transition: opacity 0.4s ease-in-out 1.2s, transform 1s ease-in-out 1s;
        display: block;
        text-shadow: 0.5px 0.5px 0 #fff;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .slidercontext {
      width: calc(100% - 20px) !important;
      right: 10px !important;

      h2 {
        font-size: 30px !important;
        padding-bottom: 0px !important;
        line-height: 32px !important;
      }

      h3 {
        font-size: 17px !important;
      }

      h4 {
        font-size: 13px !important;
      }
    }
  }
}

.flex-active-slide {
  .slidercontext {
    &, h2, h3, h4, span {
      opacity: 1 !important;
      transform: translateX(0px) !important;
    }
  }
}

.persoenliche-beratung {
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
  padding: 5px;
  background: #fff;
  .persoenliche-beratung_header {
    background: $h1--color;
    color: #fff;
    padding: 5px 10px;
    margin-bottom: 5px;
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
  .persoenliche-beratung_zeiten {
    padding: 10px;
  }
  i {
    font-size: 20px;
    color: $h1--color;
  }
  a {
    padding: 10px;
    color: $h3--color;
  }
  ul {
    li {
      &::before {
        content:'';
        display: none;
      }
      list-style-type: none;
      margin-bottom: 10px;
      padding-left: 15px;
    }
  }
}

.persoenliche-beratung--listing {
  .persoenliche-beratung--entry {
    margin-bottom: 30px;
    .persoenliche-beratung {
      height: 100%;
    }
    .persoenliche-beratung_zeiten,
    a.row {
      flex-wrap: nowrap;
    }
    .persoenliche-beratung_pic {
      img {
        max-width: 100%;
      }
    }
    h3 {
      text-align: center;
      margin-top: 10px !important;
      margin-bottom: 0 !important;
    }
    h4 {
      font-size: $body--font-size;
      font-family: $body--font-family;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.persoenliche-beratung {
  height: 100%;
  .persoenliche-beratung_zeiten,
  a.row {
    flex-wrap: nowrap;
  }
  .persoenliche-beratung_pic {
    img {
      max-width: 100%;
    }
  }
  h3 {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }
  h4 {
    font-size: $body--font-size;
    font-family: $body--font-family;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.persoenliche-beratung--einzel {
  margin-bottom: 5px;
  height: auto;
}

.navbar-collapse {
  flex-basis: auto;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}
.navbar {
  height: 100px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.nav {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-around;
  .nav-item {
    .nav-link {
      color: rgba(0,0,0,.5);
      text-shadow: 0.5px 0.5px #e6e6e6
    }
    .dropdown-menu {
      left: 50%;
      transform: translateX(-50%);
      border: 0px;
      background-color: rgba(255,255,255, 0.75);
      margin: 0px;
      padding: 0px;
      .dropdown-item {
        text-align: center;
      }
    }
  }
}

.logo {
  bottom: -10px;
  position: relative;
}
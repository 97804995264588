@import "import";
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow');
@import url('https://fonts.googleapis.com/css?family=Oswald');

/**
  FARBEN
 */

$pink: #dadada;
$pinkHover: rgba(143, 203, 1, 0.85);
$dunkel-braun: $pink;
$hell-braunHover: $dunkel-braun;
$hell-braun: #00549d;
$blau: $hell-braun;



/**
  GENERAL
 */

$body--font-family: "PT Sans Narrow";
$body--font-size: 15px;
$body--font-size-xs: 13px;
$body--font-weight: 200;
$body--font-color: black;

$h1--color: black;
$h1--font-family: "PT Sans Narrow";
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 40px;
$h1--font-weight: 100;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 0.5px 0.5px 0px #fff;


$h2--color: #666666;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-size-xs: 30px;
$h2--font-weight: 400;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;
$h2--text-align: left;

$h3--color: #999999;
$h3--font-family: "PT Sans Narrow";
$h3--font-size: 21px;
$h3--font-size-xs: 17px;
$h3--font-weight: 600;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: none;
$h3--text-align: left;
$h3--margin: 30px 0px 20px 0px !important;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$main--font-size-xs: 14px !important;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: #f2f2f2;
$header--height: auto;

$header__top-area__row--bg: #ffffff;
$header__top-area--height: 100px;
$header__bottom-area__nav--bg: rgba(143, 203, 1, 0.8);
$header__top-area__svg--color: $pink;

$header__bottom-area__nav--bg: #da6ca3;


$main--padding: $header--height 0px 0px 0px;

$flexslider__control-nav__active--color: $h1--color;
$flexslider__control-nav__normal--color: $h1--color;
$flexslider__control-nav__hover--color: #fff;

$main__a--color: #999999;
$main__a__hover--color: $hell-braun;


$footer__h2--color: #fff;
$footer__h2--font-family: "PT Sans Narrow";
$footer__h2--font-size: $h2--font-size;
$footer__h2--font-weight: $h2--font-weight;
$footer__h2--line-height: $h2--line-height;
$footer__h2--text-rendering: geometricPrecision;
$footer__h2--text-shadow: 0 -1px #a0376c;


$footer__h3--color: #fff;
$footer__h3--font-family: $h3--font-family;
$footer__h3--font-weight: $h3--font-weight;

$footer__h3--font-size: $h3--font-size;
$footer__h3--text-shadow: 0 -1px #a0376c;

$footer--color: $blau;
$footer__a--color: $blau;
$footer__a__hover--color: #c9c9c9;
$footer__a--font-weight: normal;

$footer--font-family: "PT Sans Narrow";
$footer--font-weight: 300;
$footer--font-size: 15px;
$footer--padding: 40px 0px 0px 0px;

$footer--bg: #f2f2f2;
$footer__balken-bottom--background: $dunkel-braun;
$footer__balken-bottom--color: #ffffff !important;
$footer__balken-bottom--text-shadow: none;
$footer__balken-bottom--font-family: "PT Sans Narrow";

$footer__balken-bottom2--padding: 10px;
$footer__balken-bottom2--background: $hell-braun;


$linkboxen--box-shadow: 0px 0px 20px rgba(50, 28, 12, 0.1);
$linkboxen__name--background: rgba(255, 255, 255, 0.7);
$linkboxen__imgdiv--border: 1px solid #fcfbf7;
$linkboxen__a--color: $pink;
$linkboxen__a--font-family: "PT Sans Narrow";
$linkboxen__a--font-size: 30px;
$linkboxen__a--line-height: 50px;
$linkboxen__a--font-weight: 300;

$linkboxen__a-hover--font-size: 40px !default;
$linkboxen__a-hover--line-height: 60px !default;


$rexshop__list--background: rgba(255,255,255,0.5);
$rexshop__list--font-family: "PT Sans Narrow";
$rexshop__list--color: $pink;
$rexshop__list--font-size: 30px;
$rexshop__list--line-height: 50px;
$rexshop__list--font-weight: 300;
$rexshop__list__article__preis--color: $dunkel-braun;
$rexshop__list__article__preis--font-weight: bold;

/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
@import "header/navigation";
@import "header/header";
@import "header/logo";
@import "content/content";
@import "general/schatten";
@import "general/footer";
@import "teaser/teaser";
@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts/fontawesome';

@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
@import "modules/link-box";
@import "modules/rexshop";
@import "addons/ansprechpartner";
@import "modules/maps";
@import "../css/test.css";

.map h5{
  font-size:20px;
}

.header{
  z-index: 110;
}

.header__top-blueline {
  height: 20px;
  width: 100%;
  background: linear-gradient(to right,#666666,#999999,#666666);
}

.header__top-area__row {
  background: $header__top-area__row--bg;
  height: $header__top-area--height;
  border-bottom: 20px solid #e0e0e0;

  >.container {
    height: $header__top-area--height;

    display: block;
    position: relative;
    width: auto;

  }

  .header__top-area__row__img {
    height: 100%;
    bottom: 19px;
    position: absolute;
    margin-left: -25px;
  }

  .header__top-area__row__logo {
    height: calc(100% - 25px);
    top: 10px;
    position: absolute;
    margin-left: -180px;
  }
}

.header__top-area__icons__logo {
  height: calc(100% - 30px);
  max-height: 50px;
  display: block;
  position: relative;
  img {
    height: 50px;
  }
}

.header__menu__bg {
  position: absolute;
  top: 15px;
  background: $pink;
  height: 5.5vw;
  min-height: 1vw;
  box-shadow: 0px 5px 5px rgba(33, 37, 41, 0.5);
}


.dropdown-menu{
  border-radius: 0px;
}


.slidercontext{
  width: 100%;
  justify-content: center;
  display: flex;
  left: 0%;

}

.header__bottom-area {
    left: 50%;
    transform: translateX(0%) translateY(0%) !important;
    padding: 0px;
    font-size: 15px;
    background: #dadada;
    display: flex;
    flex-direction: row;
    width: calc(70% + 30px);
    margin: auto;
}

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

article {
  padding: 20px;

  ul {
    list-style: none;
    padding: 0;
    margin-left: -15px;
  }
  li {
    padding-left: 1.3em;
  }

  li:before {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-left: -1.3em;
    width: 1.3em;
    color: $pink;
  }
}

.linkboxen {
  margin-top: 40px;
  .linkbox__name {
    div {
      text-shadow: 0.5px 0.5px 0px #fff;
    }
  }
}

.main{
  top:0px;
  padding-top:0px;

}
#main {

  .main__teaser {
    padding: unset;
    z-index: 53;
    background:#ededed;


  }

  @media (max-width: 575.98px) {
    .main__teaser{
      padding-top:0;
    }
  }
}


#main__content {
  padding-top: 1px;
  position: relative;
  min-height: 500px;
  top:200px;
  padding-bottom: 200px !important;


  //.main__content__ajax {
    //article:nth-child(1){
    //  @include gradient-y(#e5e5e5, #ffffff, 0%, 100%);
    //}
  //}
}

h2{
  color:#666666;
}

.main__teaser {
  padding: unset;
  padding-top: 65px;
  top: 170px;
}





.footer__icons {
  font-size: 60px;
  display: flex;

  a {
    flex-grow: 1;
    text-align: right;

    i {
      margin-right: -13px;
    }
  }
}

.balken-bottom {
  color: #ffffff;
  a:link {
    color: #ffffff;
  }
  a:hover {
    color: #f2f2f2;
  }
}

body {
    margin-top: -4px;
    font-size: 17px;
    background: white;
    color: $body--font-color;
    width: 100%;
    background-image: url("../images/beton_bg.png");
    background-attachment: fixed;
    background-position: 0px 0px;
    background-repeat: repeat-x;
}

#compo-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 400px;
  max-height: 335px;
  z-index: 80;
  min-width: 360px;

  .optimizedpicture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main__content__ajax {
  >article,
  >div {
    position: relative;
  }
  > .container > *,
  > .container-fluid > * {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out 0s, transform 1.0s ease-in-out 0s;
  }
}


.fadeContentIn {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.main__content__ajax .no-fade {
  opacity: 1 !important;

}

.modal-title {
  font-family: $h3--font-family;
  font-weight: $h3--font-weight;
  font-size: $h3--font-size;
  color: $h3--color;
  line-height: $h3--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
}

/**
 * LAZYLOAD
 */
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  max-width: unset !important;
  max-height: unset !important;
  top: -5px;
  left: -5px;
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s, filter 0.3s;
  transition-timing-function: ease-in-out;
  position: absolute
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  left: 0px;
}

#addedToCart {

  .modal-body {
    justify-content: center;
    display: flex;
  }
  .modal-footer {
    justify-content: space-between;
  }
}

.rexshop__list__article--preis {
  font-family: $h2--font-family;
  font-weight: $h2--font-weight !important;
  font-size: $h2--font-size;
  color: $h2--color !important;
  line-height: $h2--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
  span {
    font-size: 12px;
    font-family: $body--font-family;
    color: $body--font-color !important;
  }
}

.cc-window {
  font-family: $body--font-family !important;
}

.logo__footer {
  max-width: 270px;
}


.slidercontext {
  h2 {
    color: $blau;
    font-size: 30px;
  }
  h3, h4 {
    color: $h1--color !important;
    font-size: 18px !important;
    margin: 0 !important;
    padding: 0;
  }
}

.catlisting {
  text-align: center;
  list-style-type: none;
  li {
    a {
      font-size: 30px;
    }
  }
}

.textblock-fullwidth {
  color: #fff !important;
  h2, h3, h4, h5 {
    color: #fff;
    text-shadow: none;
  }
}

$header-bar-right-label-font-size: 9px;
header {
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  nav {
    background: #dadada;
    .nav-item {
      position: relative;
    }
  }
}
.boxen{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .active {
    display: block;
  }
  .dot-container {
    .active {
      display: unset;
    }
    .activedot {
      background-color: #cccccc;
    }
  }
}

.grauebox{
  background: rgba(218, 218, 218, 0.5);
  width: unquote("calc(33.33% - 25px)");
  margin-bottom: 35px;
  font-family: pt-sans-narrow, sans-serif;
  z-index: 3;

  li{
    padding-left:13%;
    list-style:none;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: 700;
    font-size:1vw;
    text-shadow: .5px .5px 0px white;
    }
  li:before{
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-left: -1.6em;
    width: 0.8em;
    color: red;
  }
  li:last-child
  {
    padding-bottom:7%;
  }
  h2{
    font-family: "Oswald";
    left: 22px;
    font-size: 25px;
    border-bottom: 1px;
    border-style: none none solid;
    border-bottom-color: #a9a9a9;
    color: #999999 !important;
    text-transform: uppercase;
    box-shadow: 0 1px 0 0 #fff;
    font-size: 1.7vw;
    margin: 20px 47px 10px 5%;
    width: 19vw;
    line-height: 1.8vw;
    text-shadow: 0.5px 0.5px 0 #fff;
  }
}


@media (max-width: 1400px){
  .grauebox {
    width: unquote("calc(50% - 25px)");

    h2 {
      font-family: "Oswald";
      left: 22px;
      font-size: 25px;
      border-bottom: 1px;
      border-style: none none solid;
      border-bottom-color: #a9a9a9;
      text-transform: uppercase;
      box-shadow: 0 1px 0 0 #fff;
      font-size: 2.5vw;
      margin: 20px 47px 10px 5%;
      width: 25vw;
      line-height: 1.8vw;
    }
    li{
      font-size:1.4vw;
    }
  }
}


@media (max-width: 1110px){
  .grauebox{
    background: #dadada;
    width: calc(50% - 25px);
    margin-bottom: 35px;
    font-family: pt-sans-narrow,sans-serif;
    z-index: 3;
    li{
      padding-left:13%;
      list-style:none;
      text-transform: uppercase;
      font-size: 15px;
      font-family: 'PT Sans Narrow', sans-serif;
      font-weight: 700;
      font-size: 1.2vw;

    }
  }
}



@media (max-width: 1200px){
  .grauebox{
    background: #dadada;
    width: 100%;
    margin-bottom: 35px;
    font-family: 'PT Sans Narrow', sans-serif;
    li{
      padding-left:13%;
      list-style:none;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 700;
      font-size: 2.8vw;

    }
    h2{
      font-size: 4.3vw;
      width: 70%;
      font-family: 'Oswald', sans-serif;

      line-height: 5.2vw;

    }
  }
  .boxen{

  }
}



.grauebox_text{
  font-family: 'Oswald', sans-serif;
  position: absolute;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 100px;
  opacity: 0.1;
  text-transform: uppercase;
  font-size: 6.1vw;
  width: 100%;
  height: 91%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}




// Css für die Slideshow der grauen Boxen
.next, .prev{
  display:none
}

.dot-container{
  display: none;
}

@media (max-width:1200px) {
  .prev, .next {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -30px;
    padding: 16px;
    color: #888;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    position: absolute;
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  .grauebox{
    display:none;
    margin-bottom:0px;

    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }



  .grauebox_text{
    margin-top: -205px;
    font-size: 6vw;
    display: flex;
    opacity: 1;
    color: #e40000;
  }

  .boxen{
    margin-top: 110px;
    min-height: 310px;


  }

  .next:hover, .prev:hover {
    background-color: unset !important;
    color:white;
  }



  .dot-container {
    text-align: center;
    padding: 20px;
    background-color: #DADADA;
    width:100%;
    display: block;
    max-height: 50px;

  }


  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }


  .active, .dot:hover {

  }



}


@media (max-width: 993px){
  .grauebox_text{
    margin-top: -205px;
    font-size: 7vw;

  }
}

@media (max-width: 900px){
  .grauebox_text{
    margin-top: -182px;
    min-height: 275px;
    font-size: 7vw;

  }
  .grauebox li{
    font-size: 3vw;

  }

  .boxen{
    margin-top: 110px;
  }
}


@media (max-width: 800px){
  .grauebox_text{
    margin-top: -180px;
    min-height: 225px;

  }
  .boxen{
    margin-top: 75px;
  }
}


@media (max-width: 680px){
  .grauebox_text{
    margin-top: -170px;
    min-height: 225px;
    font-size: 8vw;
  }
  .boxen{
    margin-top: 75px;
  }
}


@media (max-width: 500px){
  .grauebox_text{
    margin-top: -170px;
    font-size: 9vw;
  }
  .boxen{
    margin-top: 70px;
  }
  .grauebox li{
    font-size: 4vw;

  }
  grauebox h2{
    font-size: 6vw;
  }
}


@media (max-width: 350px){
  .grauebox_text{
    margin-top: -165px;

  }
  .boxen{
    margin-top: 40px;
  }
}

.picturewithlinkandtext{
  display: flex;
  flex-wrap:wrap;
  overflow:auto;
  justify-content: space-between;

}

.pwlat_container{
  font-family: pt-sans-narrow, sans-serif;
  color:#888888;
  width: 30%;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  max-width: 30%;


  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 5vw;
    width: 6vw;
  }
  p{
    font-size: -webkit-calc(10px + 0.6vw);
    font-size: calc(10px + 0.6vw);
    font-family: 'PT Sans Narrow',sans-serif;

  }
}

@media (max-width:700px){
  .picturewithlinkandtext{
    justify-content: center;

  }
  .pwlat_container{
    width: 51%;
    max-width:none;
    margin-bottom: 45px;

    img{
      height: 15vw;
      width: 15vw;
    }
    p {
      font-size: 4vw;

    }
  }
}

$pwlat_container-left:42px;

.simple_textblock{

  h1{
    font-family: 'Oswald', sans-serif;
    color: #e40000;
    font-size:32px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3vw;
    line-height: 6vw;
  }
  h2{
    color:#888888;
    font-size:21px;
    font-family:pt-sans-narrow, sans-serif;
    font-style: normal;
    font-weight: 700;
    margin-top: -15px;
    line-height: 4vw;
    font-size: 1.4vw;
  }
  tbody{
    font-family:pt-sans-narrow, sans-serif;
  }
}

.bildtext {
  margin-bottom: 50px;
  img {
    max-width: 100%;
  }
}

.container-fluid{
  padding: 0px 0px 25px 0px;
}

@media (min-width: 992px) {
  .col-lg-6 {
    padding: 0;
    padding-right: 40px;

  }
}

@media screen and (max-width: 1500px){
  .header__top-area__row >.container {
    max-width: 888px;
  }
}
@media screen and (max-width: 1260px){
  .header__top-area__row >.container {
    max-width: 810px;
  }
}
@media screen and (max-width: 1200px){
  .header__top-area__row >.container {
    max-width: 630px;
  }
}


article h2{
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color: #999999;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: -30px;

}

article h3{
  font-family: 'PT Sans Narrow',sans-serif;
  font-weight: bold;
  color: #bdbdbd;
  text-align: center;

}

.textblock{
  font-family: 'PT Sans Narrow',sans-serif;
  font-size: 19px;

}

#footer{
  z-index: 100;
  position: relative;
  text-shadow: -0.5px -0.5px 0 #071d50;
  height:100%;
  padding-bottom: 3vw;
  background: linear-gradient(to right,#666666,#999999,#666666);
  color:white;
  list-style: none;

  .container {
    top: -70px;
  }

  h2{
    color:white;
    margin-bottom: 3vw;
    text-transform: none;
    font-size: 1.8vw;
    font-family: 'PT Sans Narrow',sans-serif;
    font-weight: 900;

  }
  a{
    color:white;
  }

  a:hover {
    color: #cccccc;
  }

  .bg-bottom-right{
    display:none;
  }

  .allgemeineinfos {
    font-size: 19px;
    font-weight: 300;
    font-family: "PT Sans Narrow";
    li i {
      display: none;
    }
  }

  .allgemeineinfos-company-name{
    font-weight: 900!important;;
  }

  .a-map-marker-alt{
    display:none;
  }

  .fas{
    font-size: 4vw;
  }

  ul{
    font-weight: normal !important;
    display: inline;
    text-align: center;
    list-style-type: none;
  }
}

footer article.container{
  width: auto;
  height: 4vw;
  text-transform:none;
}

.balken-bottom{
  display:none;
}
.site-map{
  justify-content: center;

  h3{
    margin-top:0px !important;
    padding-bottom:0px !important;
    margin-bottom:0px !important;
    font-family: 'PT Sans Narrow',sans-serif;
    font-weight: 900;
  }
}
.footer_logo{
  img {
    max-width: 370px;
    left: 50%;
    position: relative;
    transform: translateX(-50%) translateY(-83%);
  }
}

.site-map--lev1{
  width: 15vw;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  height: 0vw;
  margin-top: 0px;
  margin-bottom: 1vw;

}

@include media-breakpoint-up(xl){
  .container {
    max-width: 1330px;
  }
}
@include media-breakpoint-down(xs) {
  #main{
    width: 100%;
    background: none;
  }
}

@include media-breakpoint-down(md) {
  .logo {
    max-height: 50px;
    top: 0px;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }
  .navbar-collapse {
    position: absolute;
    top: 50px;
    right: 0px;
    background: #dadada;
  }
  .navbar {
    max-height: 60px;
  }

  .header__top-blueline {
    height: 10px;
  }

  .main__teaser {
    top: 85px;
  }

  #main__content {
    top: 100px;
  }

  article h2 {
    font-size: 24px;
  }

  .textblock {
    font-size: 16px;
  }

  .bildtext {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
  #footer {
    h2 {
      font-size: 15px;
    }

    .shadow-top {
      top: -16px;
    }

    .container {
      top: -90px;
    }
  }

}
//TODO Variablen extrahieren

$map--container-margin: 30px 0 0 !default;
$map--container-padding: 0 0 113px !default;

$map-border: 1px solid #c0c0c0 !default;
$map-min-height: 400px !default;
$map-height: 100% !default;
$map-width: 100% !default;

$map--dialog-headline-margin: 0 5px 5px 5px !default;
$map--dialog-headline2-margin: 0 0 5px !default;
$map--dialog-headline2-text-align: center !default;


.map {
  position: relative;
  height: 100%;
  min-height: 400px;
  margin: $map--container-margin;
  padding: $map--container-padding;
}
#map {
  height: $map-height;
  margin-bottom: 0 !important;
  padding-bottom: 0;
  width: $map-width;
  min-height: $map-min-height;
  border: $map-border;

  .standortdetailsheadline {
    margin: $map--dialog-headline-margin;
  }
  .standortdetailsheadline2 {
    text-align: $map--dialog-headline2-text-align;
    margin: $map--dialog-headline2-margin;
  }
  .gm-style-iw {
    left: 30px !important;
  }
  .gm-style-iw form {
    text-align: center;
  }
  .gm-style-iw form input {
    text-align: center;
  }
}

@include mobile {
  #map {
    border:0;
  }
  .map {
    padding-bottom: 0;
  }
}
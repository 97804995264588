$flexslider__nav__a--color: #fff !default;
$flexslider__nav__a--hover-color: #fff !default;

.main__teaser {
  padding: 0;
  height: $teaser-height;
  overflow: hidden;
  position: relative; /*padding-top: 73px;*/
  width: 100% !important;
  //margin-top: @header_height;
  background: #fff;
  z-index: 50;
  max-width: none;
}

.main__teaser__tease {
  .flexslider {
    .slides {
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      width: 100% !important;

      li {
        .optimizedpicture {
          padding-top: 46.89%;
          position: relative;

          picture {
            position: absolute;
            top: 0px;
            left: 0px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .embed-responsive {
    position: relative; /*height: 100%;*/
    padding: 0;
    height: auto;
  }
}

.main__teaser__tease-overlay-container {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.flexslider__nav {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  .flex-direction-nav {
    width: 100%;
    height: 100%;
    display: flex;
    list-style-type: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 170px;

    .flex-nav-prev {
      width: 50%;
      text-align: left;
      left: 0px;
      height: 100%;

      @include media-breakpoint-down(xs) {
        font-size: 30px;
      }

      .flex-prev {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 50%;
        .fa-angle-left {
          text-shadow: -1px -1px 1px #272634;
          opacity: 0.4;
          position: absolute;
          top: calc(50% - 170px / 2);
          left: 0% !important;
          transform: translateX(-100%);
          transition: transform 0.3s ease-in-out 0s;
        }
      }

      .flex-prev:hover {
        .fa-angle-left {
          transform: translateX(0%);
        }
      }
    }
    .flex-nav-next {
      width: 50%;
      text-align: right;
      height: 100%;
      left: 50%;

      @include media-breakpoint-down(xs) {
        font-size: 30px;
      }

      .flex-next {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 50%;
        .fa-angle-right {
          text-shadow: -1px -1px 1px #272634;
          opacity: 0.4;
          position: absolute;
          top: calc(50% - 170px / 2);
          transform: translateX(50%);
          transition: transform 0.3s ease-in-out 0s, opacity 2s ease-in-out 1.8s;
        }
      }

      .flex-next:hover {
        .fa-angle-right {
          transform: translateX(-100%);

        }
      }
    }

    a {
      color: $flexslider__nav__a--color;
      &:hover, &:focus, &:active {
        color: $flexslider__nav__a--hover-color;
      }
    }
  }
}

.main__teaser__tease .teaser-text {
  font-family: $sansFontFamily;
  color: #fff;
  opacity: 1;
  margin-top: 25px;
  //text-shadow: none;
  font-size: 50px;
  font-weight: 700;
  //margin-right: 100px; TODO ???
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transform: translateY(100px);
  will-change: transform;

  $abstandTeaser: 50px;

  span {
    //background: rgba(0,0,0,0.75);
    opacity: 0;
    display: block;
    line-height: 60px;
    transition: transform 3s ease-in-out 0s, opacity 2s ease-in-out 1.8s;
    //transform: translateX(-@abstandTeaser);
    will-change: opacity, transform;
  }
  //span:nth-child(1) {
  //  font-weight: 900;
  //}
  span:nth-child(2) {
    margin-left: 70px;
    margin-top: -2px;
    //font-weight: 900;
    //transform: translateX(@abstandTeaser);
  }
  -webkit-transition: transform 0.3s ease-in-out 2s;
  -moz-transition: transform 0.3s ease-in-out 2s;
  -o-transition: transform 0.3s ease-in-out 2s;
  transition: transform 0.3s ease-in-out 2s;
  &.show {
    transform:translateY(0);
  }
  &.moving {
    -webkit-transition: transform 0s linear 0s;
    -moz-transition: transform 0s linear 0s;
    -o-transition: transform 0s linear 0s;
    transition: transform 0s linear 0s;
  }

  display: flex !important;
  flex-direction: column;
  align-items: baseline;
}
//.main__teaser__tease .teaser-text ::selection {
//  background: rgba(0,0,0,0.75);
//}
//.main__teaser__tease .teaser-text span::-moz-selection {
//  background: rgba(0,0,0,0.75);
//}

.main__teaser__tease {
  //max-height: 650px;
}
.main__teaser__tease-side-container {
  display:none;
}

@include media-breakpoint-down(xs) {
  @media screen, screen and (min-device-pixel-ratio: 1.5), screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .main__teaser__tease-side-container {
      transition: none;
      z-index: 20;
      bottom: 0;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
      display: flex;
      //display: none !important;
      flex-direction: row;
      width: 75%;
      font-size: 13px;
      font-weight: 700;
      position: absolute;
      background: rgba(255, 255, 255, 0.95);
      h6 {
        font-size: 13px;
      }
      > div {
        margin: 0;
        padding: 0;
        box-shadow: none;
        flex-direction: column;
        width: 50%;
        padding-top: 10px;
        &.overlay-tel {
          a {
            display: block;
          }
          padding-right: 5px;
          padding-left: 5px;
          text-align: center;
          img {
            left: 25%;
          }
        }
        &.overlay-time {
          img {
            left: 75%;
          }
          border-left: 1px solid #c0c0c0;
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;
          div.img-bg {
            left: 75%;
          }
          #oeffnungszeiten {
            color: $default-text-color;
            h6 {
              color: $default-text-color;
            }
          }
        }
      }
      img {
        width: auto;
        height: 100%;
        position: absolute;
        //transform: translateY(-100%);
        transform: translate(-50%, -100%);
      }
      div.img-bg {
        display: block;
        position: absolute;
        left: 25%;
        transform: translate(-50%, -100%);
        height: 95%;
        width: auto;
        margin: 0;
        padding-left: 41px;
        background: rgba(255, 255, 255, 0.95);
        z-index: -1;
      }
    }

    .teaser-text {
      transform: translateY(-100%);
    }
  }
}
@media screen and (max-width: 1020px) {
  .main__teaser__tease .teaser-text {
    font-size: 40px;
    span {
      line-height: 40px;
      &:nth-child(2) {
        margin-top: 0;
        margin-left: 90px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .main__teaser__tease .main__teaser__tease-overlay-container .teaser-text {
    margin-top: 110px;
  }
}

@include media-breakpoint-down(lg) {
  .main__teaser__tease .main__teaser__tease-overlay-container .teaser-text {
    margin-top: 130px;
    font-size: 35px;
    span {
      line-height: 35px;
    }
    span:nth-child(2) {
      margin-top: 0;
      margin-left: 60px;
    }
  }
}

@include media-breakpoint-down(xs) {
  @media screen and (min-device-pixel-ratio: 1.5), screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .main__teaser__tease .teaser-text {
      font-size: 20px;
      span {
        line-height: 20px;
        &:nth-child(2) {
          margin-top: 0;
          margin-left: 30px;
        }
      }
    }
    .main__teaser__tease .teaser-text {
      &, span {
        pointer-events: none;
      }
    }
    .main__teaser__tease .main__teaser__tease-overlay-container {
      pointer-events: none;
    }
  }
}